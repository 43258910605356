import React, { useEffect, useState } from 'react';
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import AudienceSelection from './AudienceSelection'; // Import AudienceSelection
import { useDispatch } from 'react-redux';
import { getGuestList } from '../store/guests/actions';
import { useParams } from 'react-router-dom';

export default function EventUpdate({ templateData, setShowEventUpdate }) {
  console.log("templateData received by eventUpdate",templateData);
  const dispatch = useDispatch();
  const [mediaUrl, setMediaUrl] = useState(""); // State for media URL
  const { eventId } = useParams();
  console.log("evrntID",eventId);
  useEffect(() => {
    dispatch(getGuestList(eventId,[]));
  }, []);

  const text = templateData?.additional_details?.text || "";
  const placeholderRegex = /\*(\w+)\*/g;
  const placeholders = [...text.matchAll(placeholderRegex)].map((match) => match[1]);

  // Initialize state for placeholder values
  const [dynamicPlaceholders, setDynamicPlaceholders] = useState(
    placeholders.reduce((acc, key) => ({ ...acc, [key]: "" }), {})
  );
  const [showAudienceSelection, setShowAudienceSelection] = useState(false);

  // Handle changes to placeholder inputs
  const handleInputChange = (key, value) => {
    setDynamicPlaceholders((prev) => ({ ...prev, [key]: value }));
  };

  // Render text with placeholders replaced by inputs and handle newlines
  const renderDynamicText = () => {
    const result = [];
    let lastIndex = 0;

    text.replace(placeholderRegex, (match, placeholder, index) => {
      // Push static text (including newlines handling)
      const staticText = text.slice(lastIndex, index);
      if (staticText) {
        staticText.split("\n").forEach((line, i) => {
          if (i > 0) result.push(<br key={`br-${lastIndex}-${i}`} />);
          result.push(line);
        });
      }

      // Push the input field
      result.push(
        <div key={placeholder} className="inline-block mx-1" style={{paddingBottom: '15px'}}>
          <Input
            key={placeholder}
            id={placeholder}
            value={dynamicPlaceholders[placeholder]}
            placeholder={placeholder}
            onChange={(e) => handleInputChange(placeholder, e.target.value)}
            className="inline-block w-auto border rounded px-2 py-1 mx-1"
          />
        </div>
      );

      lastIndex = index + match.length;
    });

    // Push remaining static text (including newlines handling)
    const remainingText = text.slice(lastIndex);
    if (remainingText) {
      remainingText.split("\n").forEach((line, i) => {
        if (i > 0) result.push(<br key={`br-${lastIndex}-rem-${i}`} />);
        result.push(line);
      });
    }

    return result;
  };

  return (
    <div>
      {showAudienceSelection ? (
        <AudienceSelection
          onBack={() => setShowAudienceSelection(false)}
          placeholders={{ ...dynamicPlaceholders, mediaUrl }}
          templateData={templateData}
        />
      ) : (
        <div className="p-6 max-w-2xl mx-auto bg-white rounded-xl shadow-md">
          <h1 className="text-2xl font-bold mb-4">Event Update</h1>
          <div className="bg-gray-100 p-4 rounded mb-4">
            <p className="text-base">{renderDynamicText()}</p>
          </div>
          <div className="mt-6">
            <label htmlFor="media-url" className="block text-sm font-medium text-gray-700 mb-1">
              Media URL
            </label>
            <Input
              id="media-url"
              value={mediaUrl}
              placeholder="Enter media URL"
              onChange={(e) => setMediaUrl(e.target.value)}
              className="block w-full border rounded px-3 py-2"
            />
          </div>
          <div className="mt-6 flex justify-between">
            <Button onClick={() => setShowEventUpdate(false)} variant="outline">
              Back
            </Button>
            <Button variant="outline">Save as Draft</Button>
            <Button onClick={() => setShowAudienceSelection(true)}>Next</Button>
          </div>
        </div>
      )}
    </div>
  );
}
