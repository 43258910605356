import React, { useState } from 'react';
import axios from 'axios';
import { Button } from "../components/ui/Button";
import MessageStatus from './MessageStatus';
import config from '../config';

export default function BroadcastMessage({ templateData, guestList, onBack, placeholders, messageLines }) {
  const [showMessageStatus, setShowMessageStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatGuestListData = (guestList) => {
    // Check if guestList.data exists and map accordingly
    if (guestList && guestList.data) {
      return guestList.data.map(guest => ({
        phone: guest.phone_number,
        first_name: guest.first_name,
        last_name: guest.last_name,
        ...guest
      }));
    }
    return [];
  };

  const handleSendNow = async () => {
    setLoading(true);
    setError(null);

    try {
      // Format the templates array from placeholders
      const templates = Object.entries(placeholders)
        .filter(([key]) => key !== 'mediaUrl')
        .map(([_, value]) => value);

      // Prepare the payload to match backend expectations
      const payload = {
        campaignName: templateData?.name || 'Default Campaign',
        media: {
          url: placeholders?.mediaUrl || '',
          filename: 'sample_media'
        },
        templates,
        paramsFallbackValue: { FirstName: 'User' },
        guestList: formatGuestListData(guestList)
      };

      const response = await axios.post(`${config.BACKEND_URL}/send-message`, payload);

      console.log('Message sent successfully:', response.data);
      setShowMessageStatus(true);
    } catch (err) {
      console.error('Error sending messages:', err.response || err);
      setError('Failed to send messages. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (showMessageStatus) {
    return (
      <MessageStatus
        templateData={templateData}
        messageLines={messageLines}
        guestList={guestList}
        onBack={() => { setShowMessageStatus(false); }}
        placeholders={placeholders}
      />
    );
  }

  const totalGuests = guestList?.data?.length || 0;

  return (
    <div className="p-6 max-w-2xl mx-auto bg-white rounded-xl shadow-md">
      <h1 className="text-2xl font-bold mb-4">Event Update</h1>
      <div className="mt-4 p-4 bg-gray-100 rounded-lg">
        {messageLines.map((line, index) => (
          <p 
            key={index} 
            className={index === 0 ? '' : 'mt-2'} 
            dangerouslySetInnerHTML={{ __html: line }} 
          />
        ))}
      </div>
      <p className="mb-4 text-lg">
        Sending message to {totalGuests} {totalGuests === 1 ? 'person' : 'people'}.
      </p>
      {error && (
        <p className="text-red-500 mb-4">{error}</p>
      )}
      <div className="flex justify-between">
        <Button 
          variant="outline" 
          onClick={onBack} 
          disabled={loading}
        >
          Back
        </Button>
        <Button 
          variant="outline" 
          disabled={loading}
        >
          Save as Draft
        </Button>
        <Button 
          onClick={handleSendNow} 
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send Now'}
        </Button>
      </div>
    </div>
  );
}
