import { FileText } from "lucide-react";
import { Button } from "./ui/Button";
import { useRef, useState, useEffect } from "react";
import { Upload } from 'lucide-react';
import AddTeamModal from "./AddTeamModal";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { getTeamList, uploadTeamList } from '../store/teams/actions';
import { useLocation, useParams } from "react-router-dom";

const Teams = () => {
  const { teamList, teamListLoading, currentEvent, events } = useSelector((state) => ({
    teamList: state.teamsReducer.teamMembers,
    teamListLoading: state.teamsReducer.loading,
    currentEvent: state.eventsReducer.currentEvent,
    events: state.eventsReducer.eventsList
  }));

  const location = useLocation();
  const { event } = location.state || {};
  const [teams, setTeams] = useState([]);
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const [newTeam, setNewTeam] = useState({ name: "" });
  const [columns, setColumns] = useState([]);

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { eventId } = useParams();

  useEffect(() => {
    // Fetch the team list
    dispatch(getTeamList(eventId));
  }, [dispatch, eventId]);

  useEffect(() => {
    if (teamList?.length > 0) {
      // Extract keys from the first team object to form the headers (columns).
      const keys = Object.keys(teamList[0]);
      setColumns(keys);
      setTeams(teamList);
    }
  }, [teamList]);

  const handleAddTeam = (e) => {
    e.preventDefault();
    if (newTeam.name.trim()) {
      // If you have a createTeam action, you could dispatch it here:
      // dispatch(createTeam(newTeam.name));

      // For now, just add it to the local state:
      setTeams([...teams, { id: teams.length + 1, ...newTeam }]);
      setNewTeam({ name: "" });
      setIsAddTeamModalOpen(false);
    }
  };

  const handleUploadExcel = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const binaryString = evt.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      if (!data || data.length === 0) return;

      const headers = data[0];
      setColumns(headers);

      const rows = data
        .slice(1) // Skip the header row
        .filter((row) =>
          row.some((cell) => cell && String(cell).trim() !== "")
        )
        .map((row, index) => {
          const teamObj = { id: index + 1 };
          headers.forEach((header, i) => {
            teamObj[header] = row[i] ? String(row[i]).trim() : '';
          });
          return teamObj;
        });

      dispatch(uploadTeamList(eventId, rows));
      setTeams(rows);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Teams</h2>
      <div className="flex justify-end gap-2 mb-4">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadExcel}
          accept=".xlsx, .xls"
          className="hidden"
        />
        <button
          onClick={() => fileInputRef.current.click()}
          className="flex items-center px-3 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors text-xs"
        >
          <Upload size={16} className="mr-2" />
          Upload Teams Data
        </button>
      </div>

      <AddTeamModal
        isOpen={isAddTeamModalOpen}
        onClose={() => setIsAddTeamModalOpen(false)}
        title="Add New Team"
      >
        <form onSubmit={handleAddTeam}>
          <input
            type="text"
            placeholder="Enter Team Name"
            value={newTeam.name || ""}
            onChange={(e) => setNewTeam({ ...newTeam, name: e.target.value })}
            className="w-full p-1 mb-2 text-ms border rounded"
          />
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setIsAddTeamModalOpen(false)}
              className="px-3 py-1 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors text-ms"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-3 py-1 bg-black text-white rounded-md hover:bg-gray-800 transition-colors text-ms"
            >
              Add Team
            </button>
          </div>
        </form>
      </AddTeamModal>

      {teams.length > 0 && (
        <div className="overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-xs text-left">
            <thead className="text-xxs uppercase bg-gray-100">
              <tr>
                {columns.map((column, index) => (
                  <th key={index} className="px-1 py-1">{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {teams.map((team) => (
                <tr key={team.id} className="bg-white even:bg-gray-50">
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className="px-1 py-1">{team[column]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Teams;
