import {
  UPLOAD_GUESTS_FAILURE, UPLOAD_GUESTS_SUCCESS, UPLOAD_GUESTS_REQUEST, GET_GUESTS_REQUEST,
  GET_GUESTS_SUCCESS, GET_GUESTS_FAILURE,UPDATE_GUESTS_REQUEST,UPDATE_GUESTS_SUCCESS,UPDATE_GUESTS_FAILURE
} from './actionTypes';

export const uploadGuestList = (eventId, guestList) => ({
  type: UPLOAD_GUESTS_REQUEST,
  payload: { eventId, guestList },
});

export const uploadGuestListSuccess = (userData) => ({
  type: UPLOAD_GUESTS_SUCCESS,
  payload: userData,
});

export const uploadGuestListFailure = (error) => ({
  type: UPLOAD_GUESTS_FAILURE,
  payload: error,
});

export const getGuestList = (eventId, filters) => ({
  type: GET_GUESTS_REQUEST,
  payload: { eventId, filters },
});

export const getGuestListSuccess = (userData) => ({
  type: GET_GUESTS_SUCCESS,
  payload: userData,
});

export const getGuestListFailure = (error) => ({
  type: GET_GUESTS_FAILURE,
  payload: error,
});
export const updateGuest = (eventId, guestId, updates) => ({
  type: UPDATE_GUESTS_REQUEST,
  payload: { eventId, guestId, updates },
});

export const updateGuestSuccess = (updatedGuest) => ({
  type: UPDATE_GUESTS_SUCCESS,
  payload: updatedGuest,
});

export const updateGuestFailure = (error) => ({
  type: UPDATE_GUESTS_FAILURE,
  payload: error,
});