// store/auth/actionTypes.js

export const UPLOAD_GUESTS_REQUEST = 'UPLOAD_GUESTS_REQUEST';
export const UPLOAD_GUESTS_SUCCESS = 'UPLOAD_GUESTS_SUCCESS';
export const UPLOAD_GUESTS_FAILURE = 'UPLOAD_GUESTS_FAILURE';
export const GET_GUESTS_REQUEST = 'GET_GUESTS_REQUEST';
export const GET_GUESTS_SUCCESS = 'GET_GUESTS_SUCCESS';
export const GET_GUESTS_FAILURE = 'GET_GUESTS_FAILURE';
export const UPDATE_GUESTS_REQUEST = 'UPDATE_GUESTS_REQUESTS';
export const UPDATE_GUESTS_SUCCESS = 'UPDATE_GUESTS_SUCCESS';
export const UPDATE_GUESTS_FAILURE =  'UPDATE_GUESTS_FAILURE';