// import React, { useState, useEffect, useMemo } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import BroadcastMessage from './BroadcastMessage'; // Import BroadcastMessage component
// import { getGuestList } from '../store/guests/actions';
// import { useParams } from 'react-router-dom';

// export default function AudienceSelection({ placeholders, onBack, templateData }) {
//   const { guestList } = useSelector((state) => ({
//     guestList:state.guestsReducer.guestList
//   }))
//   const [showBroadcastMessage, setShowBroadcastMessage] = useState(false);
//   const [filters , setFilters] = useState([]);
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const dispatch = useDispatch();
//   const { eventId } = useParams();


//   useEffect(()=>{
//     dispatch(getGuestList(eventId, [selectedFilters]));
//   },[selectedFilters])

//   useEffect(() => {
//     if (guestList?.data?.length && filters.length === 0) {
//       const uniqueCategories = [...new Set(guestList.data.map((item) => item.category))];
//       setFilters(uniqueCategories);
//     }
//   }, [guestList]);

//   const handleNext = () => {
//     setShowBroadcastMessage(true); 
//   };

//   const replacePlaceholders = (template, placeholders) => {
//     const placeholderRegex = /\*(\w+)\*/g;
//     return template.replace(placeholderRegex, (_, key) => {
//       const value = placeholders[key] || `<${key}>`;
//       return `<strong>${value}</strong>`;
//     });
//   };

//   const handleFilterChange = (category) => {
//     setSelectedFilters((prevFilters) =>
//       prevFilters.includes(category)
//         ? prevFilters.filter((filter) => filter !== category)
//         : [...prevFilters, category]
//     );
//   };

//   const dynamicMessage = replacePlaceholders(
//     templateData?.additional_details?.text ,
//     placeholders
//   );

//   const messageLines = dynamicMessage.split('\n');

//   return (
//     <div className="p-6">
//       {!showBroadcastMessage ? (
//         <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-x-6">
//           <div className="bg-white border rounded-lg shadow-md p-4 w-full md:w-1/2">
//             <h3 className="text-lg font-semibold">Event Update</h3>
//             <div className="mt-4 p-4 bg-gray-100 rounded-lg">
//               {messageLines.map((line, index) => (
//                 <p key={index} className={index === 0 ? '' : 'mt-2'} dangerouslySetInnerHTML={{ __html: line }} />
//               ))}
//             </div>
//           </div>

//           <div className="bg-white border rounded-lg shadow-md p-4 w-full md:w-1/2">
//             <h3 className="text-lg font-semibold">Who would you like to send the Broadcast to?</h3>

//             <div className="mt-4">
//               <label className="block text-sm font-medium text-gray-700">Audience <span className="text-red-500">*</span></label>
//               <select className="mt-1 block w-full p-2 border rounded-md">
//                 <option>Source: Guest list: Category</option>
//               </select>
//             </div>

//             <div className="mt-4">
//               <h4 className="text-sm font-semibold">Filters:</h4>
//               {filters.length > 0 ? (
//                 <div className="mt-2 space-y-2">
//                   {filters.map((category) => (
//                     <div key={category}>
//                       <input
//                         type="checkbox"
//                         id={category}
//                         name={category}
//                         checked={selectedFilters.includes(category)}
//                         onChange={() => handleFilterChange(category)}
//                       />
//                       <label htmlFor={category} className="ml-2 capitalize">
//                         {category}
//                       </label>
//                     </div>
//                   ))}
//                 </div>
//               ) : (
//                 <p className="text-sm text-gray-500">No categories available</p>
//               )}
              
//             </div>

//             <div className="mt-4 flex space-x-4">
//               <button className="bg-gray-300 text-white px-4 py-2 rounded-md hover:bg-gray-900" onClick={onBack}>Back</button>
//               <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Save as Draft</button>
//               <button
//                 className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-900"
//                 onClick={handleNext} 
//               >
//                 Next
//               </button>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <BroadcastMessage 
//         templateData={templateData}
//         messageLines={messageLines}
//         onBack={()=>setShowBroadcastMessage(false)}
//         guestList={guestList}
//         placeholders={placeholders}
//          />
//       )}
//     </div>
//   );
// }
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { X, ChevronDown, ChevronUp, Filter } from 'lucide-react';
import BroadcastMessage from './BroadcastMessage';
import { getGuestList } from '../store/guests/actions';
import { useParams } from 'react-router-dom';

const EXCLUDED_COLUMNS = ['room_no','guestID', 'last_name', 'email', 'phone_number'];

export default function AudienceSelection({ placeholders, onBack, templateData }) {
  console.log("templateData",templateData,"placeholders",placeholders);
  const { guestList } = useSelector((state) => ({
    guestList: state.guestsReducer.guestList
  }));
  const [showBroadcastMessage, setShowBroadcastMessage] = useState(false);
  const [filteredGuests, setFilteredGuests] = useState([]);
  const [openSection, setOpenSection] = useState(null);
  const [activeFilters, setActiveFilters] = useState({});
  const dispatch = useDispatch();
  const { eventId } = useParams();

  // Get all available columns from the first guest object
  const filterableColumns = useMemo(() => {
    if (!guestList?.data?.[0]) return [];
    return Object.keys(guestList.data[0]).filter(key => !EXCLUDED_COLUMNS.includes(key));
  }, [guestList?.data]);

  // Get unique values for each column
  const uniqueValues = useMemo(() => {
    const values = {};
    if (!guestList?.data || guestList.data.length === 0) return values;

    filterableColumns.forEach(column => {
      const columnValues = guestList.data.map(guest => guest[column]);
      values[column] = [...new Set(columnValues)];
    });
    
    // Sort values alphabetically
    Object.keys(values).forEach(key => {
      values[key].sort((a, b) => {
        if (typeof a === 'string' && typeof b === 'string') {
          return a.localeCompare(b);
        }
        return a - b;
      });
    });
    
    return values;
  }, [guestList?.data, filterableColumns]);
  const getFilterParams = () => {
    return Object.entries(activeFilters).map(([column, values]) => ({
      column,
      values
    }));
  };

  // Fetch guest list when filters change
  useEffect(() => {
    const filterParams = getFilterParams();
    dispatch(getGuestList(eventId, filterParams));
  }, [eventId, dispatch]);
  // Apply filters whenever activeFilters changes
  useEffect(() => {
    if (!guestList?.data) return;

    const filteredResults = guestList.data.filter(guest => {
      return Object.entries(activeFilters).every(([column, values]) => {
        return values.includes(guest[column]);
      });
    });
    setFilteredGuests(filteredResults);
  }, [activeFilters, guestList?.data]);

  const handleNext = () => {
    setShowBroadcastMessage(true);
  };

  const replacePlaceholders = (template, placeholders) => {
    const placeholderRegex = /\*(\w+)\*/g;
    return template.replace(placeholderRegex, (_, key) => {
      const value = placeholders[key] || `<${key}>`;
      return `<strong>${value}</strong>`;
    });
  };

  const handleFilterSelect = (column, value) => {
    setActiveFilters(prev => {
      const newFilters = { ...prev };
      if (!newFilters[column]) {
        newFilters[column] = [value];
      } else if (newFilters[column].includes(value)) {
        newFilters[column] = newFilters[column].filter(v => v !== value);
        if (newFilters[column].length === 0) {
          delete newFilters[column];
        }
      } else {
        newFilters[column] = [...newFilters[column], value];
      }
      return newFilters;
    });
  };

  const getActiveFilterCount = () => {
    return Object.values(activeFilters).reduce((acc, curr) => acc + curr.length, 0);
  };

  const clearFilters = () => {
    setActiveFilters({});
    setOpenSection(null);
  };

  const dynamicMessage = replacePlaceholders(
    templateData?.additional_details?.text,
    placeholders
  );

  const messageLines = dynamicMessage.split('\n');

  // Debug logging
  console.log('Filterable Columns:', filterableColumns);
  console.log('Unique Values:', uniqueValues);
  console.log('Active Filters:', activeFilters);
  console.log('Filtered Guests:', filteredGuests);

  const renderFilterSection = () => (
    <div className="w-full bg-white shadow-sm rounded-lg border">
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <Filter className="w-5 h-5 text-gray-500" />
            <h3 className="text-lg font-medium">Select Guests</h3>
            {getActiveFilterCount() > 0 && (
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                {getActiveFilterCount()}
              </span>
            )}
          </div>
          {getActiveFilterCount() > 0 && (
            <button
              onClick={clearFilters}
              className="text-sm text-gray-500 hover:text-gray-700"
            >
              Clear all
            </button>
          )}
        </div>

        <div className="space-y-2">
          {filterableColumns.map((column) => (
            <div key={column} className="border rounded-lg">
              <button
                className="w-full px-4 py-2 flex items-center justify-between text-left hover:bg-gray-50"
                onClick={() => setOpenSection(openSection === column ? null : column)}
              >
                <span className="capitalize text-sm font-medium">
                  {column.replace(/_/g, ' ')}
                </span>
                {openSection === column ? (
                  <ChevronUp className="w-4 h-4" />
                ) : (
                  <ChevronDown className="w-4 h-4" />
                )}
              </button>

              {openSection === column && uniqueValues[column]?.length > 0 && (
                <div className="px-4 py-2 border-t">
                  <div className="space-y-2 max-h-48 overflow-y-auto">
                    {uniqueValues[column].map((value) => (
                      <label key={value} className="flex items-center space-x-2 cursor-pointer hover:bg-gray-50 p-1 rounded">
                        <input
                          type="checkbox"
                          checked={activeFilters[column]?.includes(value)}
                          onChange={() => handleFilterSelect(column, value)}
                          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                       <span className="text-sm capitalize">
                      {value == null 
                       ? 'N/A' 
                      : typeof value === 'string' 
                      ? value.toLowerCase() 
                     : value.toString()}
                      </span>
                      </label>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {getActiveFilterCount() > 0 && (
          <div className="mt-4 flex flex-wrap gap-2">
            {Object.entries(activeFilters).map(([column, values]) =>
              values.map((value) => (
                <span
                  key={`${column}-${value}`}
                  className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs bg-gray-100"
                >
                  <span className="font-medium">{column.replace(/_/g, ' ')}:</span>
                  {value}
                  <button
                    onClick={() => handleFilterSelect(column, value)}
                    className="ml-1 hover:text-gray-700"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </span>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="p-6">
      {!showBroadcastMessage ? (
        <div className="flex flex-col md:flex-row items-start justify-center space-y-4 md:space-x-6">
          <div className="bg-white border rounded-lg shadow-md p-4 w-full md:w-1/2">
            <h3 className="text-lg font-semibold">Event Update</h3>
            <div className="mt-4 p-4 bg-gray-100 rounded-lg">
              {messageLines.map((line, index) => (
                <p
                  key={index}
                  className={index === 0 ? '' : 'mt-2'}
                  dangerouslySetInnerHTML={{ __html: line }}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-4 w-full md:w-1/2">
            <div className="bg-white border rounded-lg shadow-md p-4">
              <h3 className="text-lg font-semibold mb-4">
                Who would you like to send the Broadcast to?
              </h3>
              
              {renderFilterSection()}

              <div className="mt-4">
                <p className="text-sm text-gray-600">
                  Selected recipients: {filteredGuests?.length || 0}
                </p>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md p-4">
              <div className="flex space-x-4">
                <button
                  className="bg-gray-300 text-white px-4 py-2 rounded-md hover:bg-gray-900"
                  onClick={onBack}
                >
                  Back
                </button>
                <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
                  Save as Draft
                </button>
                <button
                  className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={handleNext}
                  disabled={!filteredGuests?.length}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BroadcastMessage
          templateData={templateData}
          messageLines={messageLines}
          onBack={() => setShowBroadcastMessage(false)}
          guestList={{ ...guestList, data: filteredGuests }}
          placeholders={placeholders}
        />
      )}
    </div>
  );
}