import { takeLatest, call, put } from 'redux-saga/effects';
import { uploadGuestListSuccess, uploadGuestListFailure, getGuestListSuccess, getGuestListFailure,updateGuest,updateGuestFailure,updateGuestSuccess } from './actions';
import { GET_GUESTS_REQUEST, UPLOAD_GUESTS_REQUEST,UPDATE_GUESTS_REQUEST } from './actionTypes';
import { uploadGuests, getGuestsList,updateGuest as updateGuestapi } from '../../utils/Api';


export function* handleGuestUpload(action) {
  const { eventId, guestList } = action.payload;

  try {
    const data = yield call(uploadGuests, eventId, guestList);
    yield put(uploadGuestListSuccess(data));
  } catch (error) {
    yield put(uploadGuestListFailure(error.message));
  }
}

export function* getEventGuestList(action) {
  const { eventId, filters } = action.payload;

  try {
    const data = yield call(getGuestsList, eventId, filters);
    yield put(getGuestListSuccess(data));
  } catch (error) {
    yield put(getGuestListFailure(error.message));
  }
}
export function* handleGuestUpdate(action){
  const {eventId,guestId,updates} = action.payload;
  try {
    const data = yield call(updateGuestapi, eventId, guestId, updates);
    yield put(updateGuestSuccess(data));
  } catch (error) {
    yield put(updateGuestFailure(error.message));
  }
}
export function* guestSaga() {
  yield takeLatest(UPLOAD_GUESTS_REQUEST, handleGuestUpload);
  yield takeLatest(GET_GUESTS_REQUEST, getEventGuestList);
  yield takeLatest(UPDATE_GUESTS_REQUEST,handleGuestUpdate);
}
