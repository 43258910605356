import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="text-gray-600 mb-4">Last updated: January 11, 2025</p>
      
      <div className="space-y-6">
        <p>
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </p>
        
        <p>
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Interpretation and Definitions</h2>
        <h3 className="text-xl font-semibold mb-3">Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>

        <h3 className="text-xl font-semibold mb-3">Definitions</h3>
        <p>For the purposes of this Privacy Policy:</p>
        <ul className="list-disc pl-6 space-y-4">
          <li>
            <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
          </li>
          <li>
            <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
          </li>
          <li>
            <strong>Application</strong> refers to wedsaclub, the software program provided by the Company.
          </li>
          {/* Continue with other definitions... */}
        </ul>

        {/* Additional sections following the same pattern... */}

        <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul className="list-disc pl-6">
          <li>
            By visiting this page on our website:{' '}
            <a 
              href="wedsaclub.com/privacypolicy" 
              className="text-blue-600 hover:text-blue-800"
              rel="external nofollow noopener" 
              target="_blank"
            >
              wedsaclub.com/privacypolicy
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;