const handleInvalidToken=()=>{
    localStorage.clear();
    alert("Your session has expired. Please log in again");
}

const checkResponsesForTokenError = async(response)=>{
    if(!response.ok){
        const errorReceived = await response.json();
        if(errorReceived.errorType==='invalidToken'){
            handleInvalidToken();
            throw new Error('Token expired. User logged out.');
    }
    throw new Error(errorReceived.error|| 'An unknown error occured.');

    }

    return response.json();
}
export default checkResponsesForTokenError;