
import React, { useState, useRef, useEffect } from 'react';
import { Upload, Download, Edit2, Check, X } from 'lucide-react';
import * as XLSX from 'xlsx';
import { getGuestList, updateGuest, uploadGuestList } from '../store/guests/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

export default function GuestManager() {
  const guestList = useSelector(state => state.guestsReducer.guestList);
const guestDataLoading = useSelector(state => state.guestsReducer.loading);
const error = useSelector(state => state.guestsReducer.error);
const currentEvent = useSelector(state => state.eventsReducer.currentEvent);

  const location = useLocation();
  const { event } = location.state || {};
  const [guests, setGuests] = useState([]);
  const [columns, setColumns] = useState([]);
  const [editingCell, setEditingCell] = useState(null);
  const [editValue, setEditValue] = useState('');
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { eventId } = useParams();

  useEffect(() => {
    dispatch(getGuestList(eventId, []));
  }, [currentEvent]);

  useEffect(() => {
    if (guestList) {
      setColumns(guestList?.headers);
      setGuests(guestList?.data);
    }
  }, [guestList]);
  
  const handleUploadExcel = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryString = evt.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
      
      if (!data || data.length === 0) return;
      
      const headers = data[0];
      const rows = data
        .slice(1)
        .filter((row) => row.some((cell) => cell && String(cell).trim() !== ""))
        .map((row, index) => { 
          const guest = {};
          headers.forEach((header, i) => {
            guest[header] = row[i] ? String(row[i]).trim() : '';
          });
          return guest;
        });
  
      dispatch(uploadGuestList(eventId, rows));
      window.location.reload();
    };
    reader.readAsBinaryString(file);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(guests.map(guest => {
      const exportGuest = {};
      columns.forEach(column => {
        exportGuest[column] = formatCellValue(column, guest[column]);
      });
      return exportGuest;
    }));
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Guests');
    
    XLSX.writeFile(workbook, 'guest_list.xlsx');
  };

  const handleEditClick = (guestID, column, value) => {
    setEditingCell({ guestID, column });
    setEditValue(value);
  };

  const handleSaveEdit = async (guestID, column) => {
    try {
      const guest = guests.find(g => g.guestID === guestID);
      const updatedValue = column === 'documents_uploaded'||column ==='RSVP' 
        ? editValue.toLowerCase() === 'yes'
        : editValue;
      
      const updatedGuest = { ...guest, [column]: updatedValue };

      await dispatch(updateGuest(eventId, guestID, { [column]: updatedValue }));
      // dispatch(getGuestList(eventId, []));
      window.location.reload();

      setGuests(guests.map(g => g.guestID === guestID ? updatedGuest : g));
      setEditingCell(null);
      setEditValue('');
    } catch (error) {
      console.error('Failed to update guest:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingCell(null);
    setEditValue('');
  };

  const formatCellValue = (column, value) => {
    if (column === 'documents_uploaded'||column === 'RSVP') {
      return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
    }
    return value;
  };

  return (
    <div className="w-full max-w-full p-0">
      <div className="flex justify-end mb-0 space-x-1">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleUploadExcel}
          accept=".xlsx, .xls"
          className="hidden"
        />
        <button
          onClick={() => fileInputRef.current.click()}
          className="flex items-center px-3 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors text-xs"
        >
          <Upload size={16} className="mr-2" />
          Upload Excel
        </button>
        <button
          onClick={handleExportExcel}
          className="flex items-center px-3 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors text-xs"
        >
          <Download size={16} className="mr-2" />
          Export
        </button>
      </div>

      {guests?.length > 0 && (
        <div className="overflow-x-auto shadow-md rounded-lg">
          <table className="w-full text-xs text-left">
            <thead className="text-xxs uppercase bg-gray-100">
              <tr>
                {columns.map((column, index) => (
                  <th key={index} className="px-1 py-1">{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {guests.map((guest) => (
                <tr key={guest.guestID} className="bg-white even:bg-gray-50">
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className="px-1 py-1">
                      {editingCell?.guestID === guest.guestID && editingCell?.column === column ? (
                        <div className="flex items-center space-x-1">
                          {column === 'documents_uploaded' ? (
                            <select
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              className="w-full px-1 py-0.5 border rounded text-xs"
                              autoFocus
                            >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          ) : (
                            <input
                              type="text"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              className="w-full px-1 py-0.5 border rounded text-xs"
                              autoFocus
                            />
                          )}
                          <button
                            onClick={() => handleSaveEdit(guest.guestID, column)}
                            className="p-1 text-green-600 hover:text-green-800"
                          >
                            <Check size={14} />
                          </button>
                          <button
                            onClick={handleCancelEdit}
                            className="p-1 text-red-600 hover:text-red-800"
                          >
                            <X size={14} />
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-center justify-between group">
                          <span>{formatCellValue(column, guest[column])}</span>
                          <button
                            onClick={() => handleEditClick(guest.guestID, column, formatCellValue(column, guest[column]))}
                            className="invisible group-hover:visible p-1 text-gray-600 hover:text-gray-800"
                          >
                            <Edit2 size={14} />
                          </button>
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}