// store/auth/reducers.js

import { UPLOAD_GUESTS_FAILURE, UPLOAD_GUESTS_SUCCESS, UPLOAD_GUESTS_REQUEST, GET_GUESTS_REQUEST, GET_GUESTS_SUCCESS, GET_GUESTS_FAILURE,UPDATE_GUESTS_REQUEST,UPDATE_GUESTS_SUCCESS,UPDATE_GUESTS_FAILURE } from './actionTypes';

const initialState = {
  loading: false,
  error: null,
  guestList: []
};

const guestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_GUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_GUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_GUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_GUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_GUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        guestList: action.payload.guest_list,
      };
    case GET_GUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case UPDATE_GUESTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
        case UPDATE_GUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        guestList: state.guestList ? {
          ...state.guestList,
          data: state.guestList.data.map(guest =>
            guest.id === action.payload.id ? action.payload : guest
          )
        } : null
      };
      case UPDATE_GUESTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
    default:
      return state;
  }
};

export default guestsReducer;
